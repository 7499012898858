import React, { useContext } from "react";
import Modal from "react-modal";
import moment from "moment";
import { convertTimezone } from "../helpers/common";
import eventContext from "../contexts/EventContext";

const customStyles = {
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
		width: "70%",
		maxHeight: "calc(100% - 75px)",
		overflow: "auto"
	}
};

const AgendaCard = ({ isOpen, closeModal, sessionDetails, professionalTemplate }) => {
	const { eventData } = useContext(eventContext);
	const getDate = (date, format) => {
		return moment(convertTimezone(date, eventData.time_zone)).format(format);
	};

	const sessionTime = val => {
		let startTime = getDate(`${val.start_timestamp}`, "h:mm A");
		let endTime = getDate(`${val.end_timestamp}`, "h:mm A");
		let timezone = moment.tz(`${val.end_timestamp}`, eventData.time_zone).format("z");

		return `${startTime} to ${endTime} ${timezone}`;
	};

	function convertToHTML(val) {
		return { __html: `${val?.session_description?.replace(/(?:\r\n|\r|\n)/g, "") || ""}` };
	}

	return (
		<>
			<Modal
				isOpen={isOpen}
				onRequestClose={() => closeModal()}
				shouldCloseOnEsc={false}
				shouldCloseOnOverlayClick={true}
				style={customStyles}
			>
				<div className="row p-3">
					<div>
						<i
							className="fas fa-times closeIcon d-flex justify-content-end"
							onClick={() => closeModal()}
						></i>
					</div>
					<div className="col-lg-8 col-md-6 agenda-modal-title-section mt-3">
						<div className="d-flex justify-content-between">
							<span className="agenda-modal-title">
								{sessionDetails.session_title}
							</span>
						</div>
					</div>
					<div className="col-lg-4 col-md-6 agenda-modal-date-section mt-3">
						<sapn className="agenda-modal-date">{sessionTime(sessionDetails)}</sapn>
					</div>
					<div className="col-12 agenda-modal-description-section">
						<p
							className="agenda-modal-description"
							dangerouslySetInnerHTML={convertToHTML(sessionDetails)}
						/>
					</div>
					{sessionDetails.session_speakers.length > 0 &&
						sessionDetails.session_speakers.map(val => (
							<div className="col-md-6 agenda-speaker-section">
								<div className="agenda-speaker d-flex align-items-center">
									<img src={val.image_url} className="agends-speaker-img" />
									<div className="d-flex flex-column about-speaker-div">
										<span className="speaker-name">{val.name}</span>
										<span className="speaker-designation">
											{val.designation}
										</span>
										<span className="speaker-designation">
											{val.organisation}
										</span>
										<div className="d-flex mt-2">
											{val.twitter_url && (
												<a
													href=""
													target="_blank"
													rel="noopener noreferrer"
													className="fab fa-twitter social-icon text-decoration-none"
												/>
											)}
											{val.linkedin_url && (
												<a
													href=""
													target="_blank"
													rel="noopener noreferrer"
													className="fab fa-linkedin-in social-icon text-decoration-none"
													style={{ marginLeft: 20 }}
												/>
											)}
											{val.facebook_url && (
												<a
													href=""
													target="_blank"
													rel="noopener noreferrer"
													className="fab fa-facebook-f social-icon text-decoration-none"
													style={{ marginLeft: 20 }}
												/>
											)}
										</div>
									</div>
								</div>
							</div>
						))}
				</div>
			</Modal>
			<style jsx>{`
				.closeIcon {
					font-size: 1.5rem;
					color: black;
					opacity: 0.5;
					cursor: pointer;
				}
				.agenda-modal-title {
					font-family: "Nunito";
					font-weight: 600;
					font-size: 24px;
					line-height: 33px;
					color: #161616;
				}

				.agenda-modal-date-section {
					// margin-right: 1px;
				}
				.agenda-modal-date {
					font-family: "Prompt";
					font-weight: 500;
					font-size: 18px;
					line-height: 27px;
					color: ${professionalTemplate ? "#FDB14B" : "#FB5850"};
					background: ${professionalTemplate ? "#FFF4E6" : "#FFF1F0"};
					border-radius: 5px;
					padding: 6px 13px;
					line-height: 36px;
				}
				.agenda-modal-description-section {
					margin-top: 25px;
				}
				.agenda-modal-description {
					font-family: "Prompt";
					font-weight: 400;
					font-size: 16px;
					line-height: 24px;
					color: #333333;
				}
				.agenda-speaker-section {
					margin-top: 33px;
				}
				.agenda-speaker {
					border-radius: 5px;
					border: 1px solid #00000033;
					padding: 5px 10px;
				}
				.agends-speaker-img {
					width: 50px;
					height: 50px;
					border-radius: 50%;
				}
				.about-speaker-div {
					margin-left: 15px;
				}
				.speaker-name {
					font-family: "Prompt";
					font-weight: 500;
					font-size: 16px;
					line-height: 21px;
					color: #333333;
				}
				.speaker-designation {
					font-family: "Prompt";
					font-weight: 400;
					font-size: 12px;
					line-height: 18px;
					color: #333333;
				}
				.social-icon {
					color: #333333;
				}
			`}</style>
		</>
	);
};

export default AgendaCard;
